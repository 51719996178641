// Revisa en que formulario esta el usuario------------------------------------------------------------------------------------------------------------------------------------
function checkForm() {
    let tipo_form = ""
    if(form.classList.contains('formulario-crear')) {
        tipo_form = "crear"
    } else if(form.classList.contains('formulario-perfil')) {
        tipo_form = "perfil"
    } else if(form.classList.contains('formulario-nuevo-candidato')) {
        tipo_form = "nuevo-candidato"
    }
    return tipo_form;
}


// Determina que Perfil se Ingreso--------------------------------------------------------------------------------------------------------------------------------------------

const form = document.getElementById('formulario');

if(typeof(form) != 'undefined' && form != null) {
    let perfilIngresado = form.elements.namedItem("busqueda[perfil]")
    if(perfilIngresado == null) {
        let perfilIngresado = form.elements.namedItem("perfil[numero]")
        perfilIngresado.addEventListener('input', checkPerfil)
    } else {
        perfilIngresado.addEventListener('input', checkPerfil)
    }
}

function checkPerfil(e) {
    // Check de en que formulario estamos
    let tipo_form = checkForm()

    perfilIngresado = e.target.value;
    const chequeoPerfil = document.querySelector('.chequeo-perfil')
   
    const botones_actualizar_eliminar = document.querySelector('.botones-actualizar-eliminar')
    const boton_crear = document.querySelector('.boton-crear')

    fetch("/build/perfiles.json")
        .then(function(resp) {
            return resp.json()
        })
        .then(function(perfiles) {
            if(perfilIngresado.length >= 5) {
                perfiles.some(function(perfil) {
        
                    if(perfilIngresado == perfil.numero) {
                        document.getElementById("p").innerHTML = perfil.descripcion
                        chequeoPerfil.classList.remove('no-mostrar')
                        chequeoPerfil.classList.add('valido')
                        chequeoPerfil.classList.remove('invalido')
        
                        // Botones
                        if(tipo_form == "perfil") {
                            botones_actualizar_eliminar.classList.remove('no-mostrar')
                            boton_crear.classList.add('no-mostrar')
                        }
                        return true;
            
                    } else {
                        chequeoPerfil.classList.remove('no-mostrar')
                        chequeoPerfil.classList.add('invalido')
                        chequeoPerfil.classList.remove('valido')
                        document.getElementById("p").innerHTML = "El Perfil no Existe"
        
                        // Botones
                        if(tipo_form == "perfil") {
                            botones_actualizar_eliminar.classList.add('no-mostrar')
                            boton_crear.classList.remove('no-mostrar')
                        }
        
                    }
                })   
            } else {
                chequeoPerfil.classList.add('no-mostrar');
                if(tipo_form == "perfil") {
                    botones_actualizar_eliminar.classList.add('no-mostrar')
                    boton_crear.classList.add('no-mostrar')
                }
            }      
        });    
}

// Tabs del index.php----------------------------------------------------------------------------------------------------------------------------------------------------------

const tab_busquedas = document.getElementById("boton_busquedas")
const tab_candidatos = document.getElementById("boton_candidatos")
const tabla_busquedas = document.getElementById("tabla_busquedas")
const tabla_candidatos = document.getElementById("tabla_candidatos")
const tabla_resoluciones = document.getElementById("tabla_resoluciones")
const tab_resoluciones = document.getElementById("boton_resolucion")

if(typeof(tab_busquedas) != 'undefined' && tab_busquedas != null) {
    tab_busquedas.addEventListener('click', cambiarTabBusquedas)
    tab_candidatos.addEventListener('click', cambiarTabCandidatos)
    tab_resoluciones.addEventListener('click', cambiarTabResoluciones)
}

function cambiarTabBusquedas() {
    tab_busquedas.classList.add('active')
    tab_candidatos.classList.remove('active')
    tab_resoluciones.classList.remove('active')

    tabla_busquedas.classList.remove('no-mostrar-tab')
    tabla_candidatos.classList.add('no-mostrar-tab')
    tabla_resoluciones.classList.add('no-mostrar-tab')
}

function cambiarTabCandidatos() {
    tab_busquedas.classList.remove('active');
    tab_candidatos.classList.add('active');
    tab_resoluciones.classList.remove('active')

    tabla_busquedas.classList.add('no-mostrar-tab')
    tabla_candidatos.classList.remove('no-mostrar-tab')
    tabla_resoluciones.classList.add('no-mostrar-tab')
}

function cambiarTabResoluciones() {
    tab_busquedas.classList.remove('active');
    tab_candidatos.classList.remove('active');
    tab_resoluciones.classList.add('active')

    tabla_busquedas.classList.add('no-mostrar-tab')
    tabla_candidatos.classList.add('no-mostrar-tab')
    tabla_resoluciones.classList.remove('no-mostrar-tab')
}

// Tabs del administrador----------------------------------------------------------------------------------------------------------------------------------------------------------


const forms = [
    {
        form: document.getElementById("datos_iniciales"),
        tab: document.getElementById("boton_datos_iniciales")
    },
    {
        form: document.getElementById("contacto"),
        tab: document.getElementById("boton_contacto")
    },
    {
        form: document.getElementById("entrevista"),
        tab: document.getElementById("boton_entrevista")
    },
    {
        form: document.getElementById("definicion"),
        tab: document.getElementById("boton_definicion")
    },
    {
        form: document.getElementById("resolucion"),
        tab: document.getElementById("boton_resolucion")
    }
]

if(typeof(forms[0].form) != 'undefined' && forms[0].form != null) {
    forms.forEach(form => {
        form.tab.addEventListener('click', cambiarTab)
    });
}

function cambiarTab(e) {
    console.log('hola4')
    forms.forEach(form => {
        if(form.tab == e.target) {
            form.tab.classList.add('active')
            form.form.classList.remove('no-mostrar-tab')
        } else {
            form.tab.classList.remove('active')
            form.form.classList.add('no-mostrar-tab')
        }
    })
}

// Alerta de Eliminar Registro -------------------------------------------------------------------------

const botones_eliminar = document.getElementsByClassName("botonjs");
const numBotones = botones_eliminar.length

if(botones_eliminar != 'undefined' && botones_eliminar != null) {
    for (var i = 0; i < numBotones; i++) {
        botones_eliminar[i].addEventListener('click', AlertaEliminar)
    }
}

function AlertaEliminar() {
    let resultado = confirm('¿Seguro que desea Eliminar este Registro?')
    if(resultado == false) {
        event.preventDefault()
    }
}

// Verifica si el Nombre ya fue Ingresado--------------------------------------------------------------------------------------------------------------------------------------------

const form_nuevo_candidato = document.getElementById('formulario-nuevo-candidato');

if(typeof(form_nuevo_candidato) != 'undefined' && form_nuevo_candidato != null) {
    let candidatoIngresado = form_nuevo_candidato.elements.namedItem("candidato[nombre]")
    candidatoIngresado.addEventListener('input', checkNombre)
}

function checkNombre(e) {
    candidatoIngresado = e.target.value
    const div = document.querySelector('.chequeo-nombre')

    // Match verifica que exista un match para el str ingresado, tal que el al div no se le aplique "no-mostrar" si no hay matches
    // El contador de Matches es solo un limite a la cantidad de candidatos que se muestran a la vez
    let match = false
    let contadorMatches = 0

    fetch("/build/candidatos.json")
        .then(function(resp) {
            return resp.json()
        })
        .then(function(candidatos) {
            // Vaciar el Div
            while (div.firstChild) {
                div.removeChild(div.lastChild)
            }
            candidatos.some(function(candidato) {
                if(candidato[1].toLowerCase().indexOf(candidatoIngresado.toLowerCase()) >= 0 & candidatoIngresado != '' & contadorMatches < 8) {
                    // Crear un enlace por cada resultado matcheado
                    const enlace = document.createElement("a")
                    enlace.textContent = candidato[1]
                    enlace.setAttribute('href', `descripcion?id=${candidato[0]}&equipo=99`)
                    enlace.setAttribute('target', '_blank')
                    enlace.classList.add('enlace')
                    div.append(enlace)

                    div.classList.remove('no-mostrar')
                    match = true
                    contadorMatches += 1

                } else {
                    if (match == false){
                        div.classList.add('no-mostrar')
                    }    
                }
            })
        })    
}

